import React from 'react'
import { EventRegistrationPage } from './_regPage'
import { eventData } from '../../api/eventData'

export default function EventWithBookPage({
  pageContext: { id, title, subText, dateText },
}) {
  return (
    <EventRegistrationPage
      title={title}
      subtitle={subText}
      date={dateText}
      eventId={id}
      formIds={eventData[id]?.eventWithBookFormId}
    />
  )
}
